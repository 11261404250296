import Link from "next/link";
import React, { ReactElement } from "react";
import { Button, ButtonGroup, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { BuddyStatuses, useCurrentUser, userIsBuddy } from "../../libs/apiHelpers/profile";
import { Routes } from "../../libs/constants";

import { Themes } from "../../libs/helpers/themeSwitcher";
import { clearUserSession } from "../../libs/helpers/userSession";
import useTheme from "../../libs/hooks/useTheme";
import { ServiceLinks } from "../Footer/Footer";
import NotificationsBell from "../Notifications/NotificationsBell";
import Brand from "../../assets/brand.svg";
import { showDebugStaff } from "../../libs/helpers/devHelpers";
import { reachGoal } from "../../libs/helpers/metrics";
import { useOpenedClaims, useProgressClaims } from "../../libs/apiHelpers/claims";

export default function Header(): ReactElement {
  const currentTheme = useTheme();
  const { user, isLoggedIn } = useCurrentUser();
  const { claims: openedClaims } = useOpenedClaims();
  const { claims: progressClaims } = useProgressClaims();
  const hasClaims = openedClaims?.length > 0 || progressClaims?.length > 0;

  const isBuddy = userIsBuddy();
  return (
    <div className="bg-light header">
      <div className="container px-0">
        <Navbar
          style={{ backgroundColor: showDebugStaff && isBuddy ? "rgb(241, 201, 116)" : undefined }}
          expand="xl"
          variant={currentTheme === Themes.light ? "light" : "dark"}>
          <Link href={Routes.Home} passHref>
            <Navbar.Brand>
              <Image src={Brand} alt="brand" style={{ width: 200 }} />
            </Navbar.Brand>
          </Link>
          {showDebugStaff && isBuddy && <span>buddy</span>}
          {isLoggedIn && user && user.user && (
            <div className="d-xl-none ml-auto text-muted">
              <NotificationsBell userId={user.user.id} />
            </div>
          )}
          <Navbar.Toggle onClick={() => reachGoal("Click_Mobile_Burger")} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="d-none d-xl-flex row no-gutters ml-auto align-items-center">
              {isLoggedIn && (
                <Nav className="mr-2">
                  <Link href={Routes.Home} passHref>
                    <Nav.Link>{hasClaims ? "Your requests" : "Create request"}</Nav.Link>
                  </Link>
                </Nav>
              )}
              {isBuddy && (
                <Nav className="mr-2">
                  <Link href={Routes.Claims} passHref>
                    <Nav.Link>Apply for request</Nav.Link>
                  </Link>
                </Nav>
              )}
              <Nav>
                <Link href={Routes.About} passHref>
                  <Nav.Link>About</Nav.Link>
                </Link>
              </Nav>
              {isLoggedIn && user && user.user && (
                <div className="d-none d-xl-block ml-xl-2 text-muted">
                  <NotificationsBell userId={user.user.id} />
                </div>
              )}
              <div className="ml-lg-4">
                {isLoggedIn ? (
                  <Dropdown as={ButtonGroup} alignRight>
                    <Link href={!user ? Routes.Registration : Routes.Profile}>
                      <Button variant="outline-secondary">{user?.first_name || "unknow"}</Button>
                    </Link>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu>
                      {!user ? (
                        <Link href={Routes.Registration} passHref>
                          <Dropdown.Item>Finish registration</Dropdown.Item>
                        </Link>
                      ) : (
                        <>
                          <Link href={Routes.Profile} passHref>
                            <Dropdown.Item>Profile</Dropdown.Item>
                          </Link>
                          {user?.buddy_status === BuddyStatuses.notBuddy && (
                            <Link href={Routes.ProfileBuddy} passHref>
                              <Dropdown.Item>Become buddy</Dropdown.Item>
                            </Link>
                          )}
                        </>
                      )}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          reachGoal("Click_Logout_Button", { place: "Header" });
                          clearUserSession();
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Link href={Routes.Login} passHref>
                    <Button variant="outline-primary">Login</Button>
                  </Link>
                )}
              </div>
            </div>

            <div className="d-xl-none">
              <ServiceLinks />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
