import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import BSIcon from "../BSIcon/BSIcon";

import styles from "../../styles/Notifications.module.css";
import { ProfileIconSmall } from "../Profile/ProfileIcon";
import { ChatroomMeta, getChatroomsRef, useChatrooms } from "../../libs/apiHelpers/chat";
import { reachGoal } from "../../libs/helpers/metrics";

interface NotificationsProps {
  userId: number;
}

interface ChatroomInterface {
  [chatId: string]: ChatroomMeta;
}

const chatroomsCache: ChatroomInterface = {};

const NotificationsBell = (props: NotificationsProps) => {
  const { userId } = props;

  const { chatrooms } = useChatrooms();
  const [chatroomsNotifications, chatroomsNotificationsSet] = useState<ChatroomInterface>({});

  const chatNotificationsList = Object.keys(chatroomsNotifications);
  const isActive = chatNotificationsList?.length > 0;

  useEffect(() => {
    if (!chatrooms || Object.keys(chatroomsCache).length === chatrooms.length) {
      return;
    }
    chatrooms.forEach((chatroom) => {
      const chatroomId = chatroom.uuid;
      let chatroomRef;
      try {
        chatroomRef = getChatroomsRef(chatroomId).child("meta");
        chatroomRef.on("value", (snapshot) => {
          if (!snapshot.exists()) {
            return null;
          }
          const value: ChatroomMeta = snapshot.val();
          if (value.applicantLast.id === userId || value.read) {
            // last message by current user
            delete chatroomsCache[chatroomId];
          } else {
            chatroomsCache[chatroomId] = value;
          }

          chatroomsNotificationsSet({ ...chatroomsCache });
        });
      } catch (e) {
        chatroomRef?.off("value");
        delete chatroomsCache[chatroomId];
        chatroomsNotificationsSet({ ...chatroomsCache });
      }
    });
  }, [chatrooms]);

  const onNotificationBellClick = () => {
    reachGoal("Click_Notification_bell", { isOnly: true, hasNotifications: isActive });
  };
  return (
    <Dropdown alignRight onClick={onNotificationBellClick}>
      <Dropdown.Toggle className="clean" variant="link" id="dropdown-notifications">
        {isActive && <div className={styles.notificationDot} />}
        <BSIcon size={16} name="bell-fill" />
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: 250, overflowY: "auto", maxHeight: 300 }}>
        {isActive ? (
          chatNotificationsList.map((chatId) => {
            const { claimId, applicantLast } = chatroomsNotifications[chatId];
            // const isUser
            return (
              <Link key={chatId} passHref href={`/claims/${claimId}/chats/${chatId}`}>
                <Dropdown.Item>
                  <div className="row no-gutters py-1 mb-2 cursor-pointer">
                    <ProfileIconSmall photo={applicantLast.image} />
                    <div className="ml-2">{applicantLast.firstName}</div>
                  </div>
                </Dropdown.Item>
              </Link>
            );
          })
        ) : (
          <p className="text-center mb-0">No new notifications</p>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsBell;
