import React, { ReactElement } from "react";
import { Image, ImageProps } from "react-bootstrap";

interface Props extends ImageProps {
  photo: string;
}

export function ProfileIconSmall({ photo, className, ...props }: Props): ReactElement {
  return (
    <Image
      width={25}
      height={25}
      className={`d-inline-block rounded-circle ${className || ""}`}
      src={photo}
      alt="avatar"
      {...props}
    />
  );
}
