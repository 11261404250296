import axios from "axios";
import { withApiErrors } from "./errorHandlers";

interface FetcherOprionsInterface {
  errorTitle?: string;
  url?: string;
  withLimits?: boolean;
  ignoreErrors?: number[];
  params?: Object;
}

export const withFetcher = (options: FetcherOprionsInterface = {}) => {
  let fetcherParams: any = {
    ...(options.params || {})
  };

  if (options.withLimits) {
    fetcherParams.limit = 1000; //crunch
  }
  return function fetcher(url: string) {
    const source = axios.CancelToken.source();
    const requestTimeout = setTimeout(() => {
      source.cancel();
    }, 5000); // 5 sec to handle request

    return axios
      .get(options.url || url, {
        params: fetcherParams,
        cancelToken: source.token
      })
      .then((res) => {
        clearTimeout(requestTimeout);
        return res.data;
      })
      .catch((errObj) => {
        clearTimeout(requestTimeout);
        withApiErrors({ errorTitle: options.errorTitle || "Can't load request", ignoreErrors: options.ignoreErrors })(
          errObj
        );
      });
  };
};
