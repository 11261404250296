import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { needDebugInfo, NEED_DEBUG_INFO_PARAM } from "../../libs/helpers/devHelpers";
import { removeFromSession, writeToSession } from "../../libs/helpers/storage";

export default function DebugToggler() {
  const [isDebug, isDebugSet] = useState(false);
  useEffect(() => {
    isDebugSet(needDebugInfo());
  }, []);

  const onDebugToggle = () => {
    if (needDebugInfo()) {
      removeFromSession(NEED_DEBUG_INFO_PARAM);
      isDebugSet(false);
    } else {
      writeToSession(NEED_DEBUG_INFO_PARAM);
      isDebugSet(true);
    }
  };
  return (
    <Form.Check
      onChange={onDebugToggle}
      defaultChecked={isDebug}
      type="checkbox"
      id="toggle-debug-mode"
      label="Debug mode"
    />
  );
}
