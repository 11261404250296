import React, { useContext, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { UserContext } from "../../libs/UserContext";
import { verifyEmail } from "../../libs/apiHelpers/auth";
import { withApiErrors } from "../../libs/apiHelpers/errorHandlers";
import { reachGoal } from "../../libs/helpers/metrics";

export default function VerifyEmailAlert() {
  const { email } = useContext(UserContext);

  const [verified, verifiedSet] = useState(false);
  const [processing, processingSet] = useState(false);
  const verifyEmailHandler = () => {
    processingSet(true);
    return verifyEmail(email)
      .then(() => {
        reachGoal("Click_Send_Again_Verification_Email_Warning");
        verifiedSet(true);
      })
      .catch(withApiErrors({}))
      .finally(() => processingSet(false));
  };

  return (
    <div>
      <Alert className="text-center mb-0" variant="warning">
        Verify your email to create requests or become buddy
        <br />
        <small>
          Didn't receive veirification letter?{" "}
          {verified ? (
            <span className="text-success">Sent</span>
          ) : (
            <Button size="sm" disabled={processing} onClick={verifyEmailHandler} variant="link">
              Send again
            </Button>
          )}
        </small>
      </Alert>
    </div>
  );
}
