import { AxiosError } from "axios";
import firebase from "firebase/app";
import useSWR from "swr";
import { isUserEmailVerified, OpenProfile, OpenUser, useCurrentUser } from "./profile";
import { withFetcher } from "./_fetcher";

export const CHAT_SERVICE_MESSAGES = {
  CANDIDATE_ACCEPTED: "Candidate accepted",
  CLAIM_CLOSED_BY_USER: "Claim closed by user"
};

export interface MessageInterface {
  userId: number;
  text: string;
  created_at: string;
}

export interface PrivateChatRoom {
  uuid: string;
  applicant: OpenUser;
  respondent: OpenUser;
  created_at: string;
}
export interface ChatroomsResponse {
  count: number;
  next: string;
  previous: string;
  results: PrivateChatRoom[];
}

export function useChatrooms() {
  const { isLoggedIn } = useCurrentUser();
  const isEmailVerified = isUserEmailVerified();
  const { data, error } = useSWR<ChatroomsResponse, AxiosError>(
    isLoggedIn && isEmailVerified ? `/chat/rooms/` : null,
    withFetcher({ errorTitle: "Can't load chatrooms" })
  );
  return {
    chatrooms: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function getChatroomsRef(chatroomId: string) {
  return firebase.database().ref(`chatrooms/${chatroomId}`);
}

export function getMessagesRef(chatId: string) {
  return firebase.database().ref(`chats/${chatId}`);
}

interface UserShortInterface {
  id: number;
  image: string;
  firstName: string;
}
export interface ChatroomMeta {
  claimId: number;

  read: boolean; // user read message
  notified: boolean; // user was notified about message by email

  applicantLast: UserShortInterface;
  respondentLast: UserShortInterface;
}

export function readChatroom(chatId: string) {
  getChatroomsRef(chatId).child("meta/read").set(true).catch();
  getChatroomsRef(chatId).child("meta/notified").set(true).catch();
}

export function pushMessage({
  chatId,
  currentUser,
  respondent,
  message,
  claimId
}: {
  chatId: string;
  currentUser: OpenProfile;
  respondent: OpenProfile;
  message: string;
  claimId: number;
}) {
  const userId = currentUser.user.id;
  const chatroomMeta: ChatroomMeta = {
    claimId: claimId,

    read: false,
    notified: false,

    applicantLast: {
      id: userId,
      image: currentUser.image_small,
      firstName: currentUser.first_name
    },
    respondentLast: {
      id: respondent.user.id,
      image: respondent.image_small,
      firstName: respondent.first_name
    }
  };
  getChatroomsRef(chatId).child("meta").set(chatroomMeta).catch();
  return getMessagesRef(chatId).push({
    userId: userId,
    text: message,
    created_at: new Date().toString()
  });
}
