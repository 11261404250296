import axios, { AxiosError, AxiosPromise } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../UserContext";
import { City } from "./geo";
import { withFetcher } from "./_fetcher";

export interface OpenUser {
  id: number;
  created_at: string;
}

interface PrivateUser extends OpenUser {
  email: string;
  password: string;
  is_email_verified: boolean;
}

export enum Activities {
  misc = "misc",
  culture = "culture",
  club = "club",
  food = "food",
  sport = "sport",
  tourism = "tourism",
  hotel = "hotel",
  shopping = "shopping"
}

export enum Genders {
  male = "male",
  female = "female"
}

export enum Networks {
  facebook = "facebook",
  instagram = "instagram",
  vk = "vk"
}

export interface OpenProfileActivity {
  type: Activities;
}

export interface OpenSocialLink {
  network: Networks;
  url: string;
}

export enum BuddyStatuses {
  notBuddy = 0,
  pending = 1,
  isBuddy = 2,
  declined = 3
}

export interface OpenProfile {
  user: OpenUser;
  city: City;
  // city_id: number;
  activities: OpenProfileActivity[];
  first_name: string;
  last_name: string;
  gender: Genders;
  dob: string;
  bio: string;
  price: number;
  image: string;
  image_large: string;
  image_medium: string;
  image_small: string;
  image_tiny: string;
  buddy_status: BuddyStatuses;
  created_at: string;
}

export interface PrivateProfile extends OpenProfile {
  user: PrivateUser;
  social_links: OpenSocialLink;
  contacts: string;
  is_manual: boolean; // if user signup with social network and need to review imported info
}

export function useCurrentUser() {
  const { userId: currentUserId, userRole } = useContext(UserContext);
  const { data, error } = useSWR<PrivateProfile, AxiosError>(
    currentUserId ? `/profiles/${currentUserId}/` : null,
    withFetcher({
      ignoreErrors: [404],
      errorTitle: "Can't load current user profile"
    })
  );
  const isFetching = currentUserId && !error && !data;
  const isLoading = userRole === null || isFetching;
  const isLoggedIn = (data && !error) || (!data && error?.response?.status === 404); // profile not created
  return {
    user: data,
    isLoading,
    isLoggedIn,
    isError: error
  };
}

export function useUser(id?: number) {
  const { data, error } = useSWR<OpenProfile, AxiosError>(
    id ? `/profiles/${id}/` : null,
    withFetcher({
      errorTitle: "Can't load profile"
    })
  );
  return {
    user: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function isUserEmailVerified() {
  const { user, isLoggedIn } = useCurrentUser();
  const { isEmailVerified } = useContext(UserContext);

  return isLoggedIn && (isEmailVerified || user?.user?.is_email_verified); // info from token can be cached. Issue #17
}

export interface EditProfilePayload {
  first_name?: string;
  last_name?: string;
  gender?: Genders;
  dob?: string;
  bio?: string;

  // buddy fields
  price?: number;
  city_id?: number;
  city?: City;
  contacts?: string;
  activities?: OpenProfileActivity[];
}

export async function editUserProfile(userId: number, payload: EditProfilePayload) {
  const res = await axios.put(`/profiles/${userId}/`, payload);
  return true;
}

export async function becomeBuddy(userId: number) {
  const res = await axios.put(`/profiles/${userId}/buddy/`);
  return true;
}

export async function abortBuddy(userId: number) {
  const res = await axios.delete(`/profiles/${userId}/buddy/`);
  return true;
}

export function userIsBuddy() {
  const { user } = useCurrentUser();
  return user?.buddy_status === BuddyStatuses.isBuddy;
}

interface UpdatePhotoResponse {
  image: string;
}
export async function editUserPhoto(userId: number, payload: FormData) {
  const res = await axios({
    method: "put",
    url: `/profiles/${userId}/photo/`,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" }
  });
  const updatedUser: UpdatePhotoResponse = res.data;
  return {
    image: updatedUser.image
  };
}

export async function createUserProfile(payload: EditProfilePayload) {
  const res = await axios.post(`/profiles/`, payload);
  return true;
}

export enum WalletStatuses {
  none = 0,
  creating = 10,
  processing = 20,
  registered = 30
}

interface WalletStatusResponse {
  status: WalletStatuses;
}

export function useWalletStatus() {
  const { userId: currentUserId } = useContext(UserContext);
  const { isLoggedIn } = useCurrentUser();
  const isBuddy = userIsBuddy();

  const { data, error } = useSWR<WalletStatusResponse, AxiosError>(
    isLoggedIn && isBuddy ? `/profiles/${currentUserId}/accounts/default/status` : null,
    (url: string) => axios.patch(url).then((res) => res.data)
  );
  return {
    isLoading: !data && !error,
    status: data?.status || WalletStatuses.none
  };
}

export async function createWallet(userId) {
  const res = await axios.post<{ url: string }>(`/profiles/${userId}/accounts`);
  return res.data.url;
}

export async function deleteWallet(userId) {
  const res = await axios.delete<{ url: string }>(`/profiles/${userId}/accounts/default`);
  return res.status === 200;
}
