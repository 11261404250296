import axios, { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { Routes } from "../constants";
import { isDevEnvironment } from "../helpers/devHelpers";
import { reachGoal } from "../helpers/metrics";
import { pushLog, pushPageError } from "../helpers/pageState";
import { clearUserSession, refreshUserSessionFromStorage } from "../helpers/userSession";

interface ApiErrorHandlerInterface {
  fieldsList?: string[];
  helpers?: FormikHelpers<any>;
  errorTitle?: string;
  ignoreErrors?: number[];
  ignoreRefreshToken?: boolean;
}

export function withApiErrors({
  errorTitle = "Response error",
  ignoreErrors = [],
  helpers,
  fieldsList = [],
  ignoreRefreshToken
}: ApiErrorHandlerInterface) {
  return function (errorsObj: AxiosError | string) {
    if (typeof errorsObj === "string") {
      pushPageError({ title: "Unhandled rejection", message: errorsObj });
      throw errorsObj;
    }

    let response = errorsObj?.response?.data;
    let code = errorsObj?.response?.status;
    if (code === 304) {
      return null; // ignore not modified warnings
    }

    if (!ignoreRefreshToken && code === 401) {
      return refreshUserSessionFromStorage();
    }

    if (!code || code === 500) {
      reachGoal("Redirect_500_Page", {
        errorObj: JSON.stringify(response)
      });
      if (isDevEnvironment) {
        pushLog(axios.isCancel(errorsObj) ? "cancelled request" : "error 500");
      } else {
        window.location.href = Routes[500];
      }
      return null;
    }

    if (ignoreErrors.includes(code)) {
      throw errorsObj; // pass request ignored codes next to handlers
    }

    if (!response) {
      // js evaluation error
      pushPageError({ title: "Script error", message: JSON.stringify(errorsObj), code: code });
      throw errorsObj;
    }

    if (typeof response === "string") {
      pushPageError({ title: errorTitle, message: response, code: code });
      throw errorsObj;
    }

    Object.keys(response).forEach((key) => {
      const errorText = response[key];
      if (key === "detail") {
        return pushPageError({ title: errorTitle, message: errorText, code: code });
      }
      if (key === "non_field_errors") {
        return errorText.forEach((errorStr: string) => {
          pushPageError({ title: errorTitle, message: errorStr, code: code });
        });
      }

      // check fields errors
      if (fieldsList.includes(key)) {
        helpers && helpers.setFieldError(key, errorText);
      } else {
        pushPageError({ title: errorTitle, message: `${key}: ${errorText}` });
      }
    });
  };
}
