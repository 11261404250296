import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../UserContext";
import { City } from "./geo";
import { OpenProfileActivity, OpenProfile, OpenSocialLink, useCurrentUser, isUserEmailVerified } from "./profile";
import { withFetcher } from "./_fetcher";

export enum ClaimStatuses {
  Created = 0,
  Open = 10,
  Payment = 15,
  Progress = 20,
  Disputed = 25, // claim
  Done = 30,
  Canceled = 40,
  Rejected = 50,
  Declained = 60
}

export interface RespondentProfile extends OpenProfile {
  social_links: OpenSocialLink;
  contacts: string;
}

export interface RespondedClaimCandidate {
  id: number;
  price: string;
  activities: OpenProfileActivity[];
  respondent_profile: RespondentProfile;
  chatroom: null | string;
  is_accepted: boolean;
  is_left: boolean;
}

export interface OpenClaim {
  id: number;
  city: City;
  status: ClaimStatuses;
  begins_at: string;
  ends_at: string;
  details: string;
  price: string; //decimal
  activities: OpenProfileActivity[];
  applicant_profile: OpenProfile;
  candidate_count: number;
  candidate_accepted: RespondedClaimCandidate;
  current_user__is_respondent: boolean;
  current_user__chatroom: false | string;
  created_at: string;
}

export interface PrivateClaim extends OpenClaim {
  city_id: number;
}

export interface ClaimsListResponse {
  count: number;
  next: string;
  previous: string;
  results: PrivateClaim[];
}

export function useOpenedClaims() {
  const { user, isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && user ? "/claims/opened" : null,
    withFetcher({
      errorTitle: "Can't load opened requests",
      url: "/claims/",
      withLimits: true,
      params: {
        status__gte: ClaimStatuses.Open,
        status__lt: ClaimStatuses.Progress
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProgressClaims() {
  const { user, isLoggedIn } = useCurrentUser();
  const isEmailVerified = isUserEmailVerified();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && isEmailVerified && user ? "/claims/progress" : null,
    withFetcher({
      errorTitle: "Can't load progress requests",
      url: "/claims/",
      withLimits: true,
      params: {
        status__gte: ClaimStatuses.Progress,
        status__lt: ClaimStatuses.Done
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useClosedClaims() {
  const { user, isLoggedIn } = useCurrentUser();
  const isEmailVerified = isUserEmailVerified();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && isEmailVerified && user ? "/claims/closed" : null,
    withFetcher({
      errorTitle: "Can't load closed requests",
      url: "/claims/",
      withLimits: true,
      params: {
        status: ClaimStatuses.Done
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useBuddyClaimsOpened() {
  const { user, isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && user ? "/claims/buddy/opened" : null,
    withFetcher({
      errorTitle: "Can't load buddy opened requests",
      url: "/claims/buddy/",
      withLimits: true,
      params: {
        status__gte: ClaimStatuses.Open,
        status__lt: ClaimStatuses.Progress
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useBuddyClaimsFiltered(activities: string) {
  const { user, isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && user ? ["/claims/buddy/opened", activities] : null,
    (url: string) =>
      axios
        .get("/claims/buddy/", {
          params: {
            status: ClaimStatuses.Open
          }
        })
        .then((res) => res.data)
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useBuddyClaimsProgress() {
  const { user, isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && user ? "/claims/buddy/progress" : null,
    withFetcher({
      errorTitle: "Can't load buddy progress requests",
      url: "/claims/buddy/",
      withLimits: true,
      params: {
        status__gte: ClaimStatuses.Progress,
        status__lt: ClaimStatuses.Done,
        current_user__is_candidate_accepted: true
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useBuddyClaimsClosed() {
  const { user, isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimsListResponse, AxiosError>(
    isLoggedIn && user ? "/claims/buddy/closed" : null,
    withFetcher({
      errorTitle: "Can't load buddy closed requests",
      url: "/claims/buddy/",
      withLimits: true,
      params: {
        status: ClaimStatuses.Done,
        current_user__is_candidate_accepted: true
      }
    })
  );
  return {
    claims: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

export function useClaim(id: number | string) {
  const { userRole } = useContext(UserContext);
  const { data, error } = useSWR<OpenClaim, AxiosError>(
    id && userRole !== null ? `/claims/${id}/` : null,
    withFetcher({
      errorTitle: "Can't load request details"
    })
  );
  return {
    claim: data,
    isLoading: !error && !data,
    isError: error
  };
}

export interface ClaimCandidatesResponse {
  count: number;
  next: string;
  previous: string;
  results: RespondedClaimCandidate[];
}
export function useClaimCandidates(id: number | string) {
  const { isLoggedIn } = useCurrentUser();
  const { data, error } = useSWR<ClaimCandidatesResponse, AxiosError>(
    isLoggedIn && id ? `/claims/${id}/candidates/` : null,
    withFetcher({
      errorTitle: "Can't load request candidates",
      withLimits: true
    })
  );
  return {
    candidates: data?.results,
    isLoading: !error && !data,
    isError: error
  };
}

interface CreateClaimPayload {
  city_id: number;
  begins_at: string;
  ends_at: string;
  details: string;
  price: string; //decimal
  activities: OpenProfileActivity[];
}
export function createClaim(payload: CreateClaimPayload) {
  return axios.post<PrivateClaim>(`/claims/`, payload);
}

export function cancelClaim(id: number | string) {
  return axios.delete(`/claims/${id}/`);
}

export function closeClaim(id: number | string) {
  return axios.delete(`/claims/${id}/contract`);
}

export function applyClaim(id: number | string, price: number | string) {
  return axios.post(`/claims/${id}/candidates/`, {
    price: price
  });
}

export function chooseCandidate(claimId: number | string, candidateId: number | string) {
  return axios.put<{ secret: string }>(`/claims/${claimId}/candidates/${candidateId}/contract`);
}

export function removeCandidate(claimId: number | string, candidateId: number | string) {
  return axios.delete(`/claims/${claimId}/candidates/${candidateId}/contract`);
}
