// import { useState, useEffect } from "react";
// import { CHANGE_THEME_EVENT } from "../constants";
import { /* getCurrentTheme,  */ Themes } from "../helpers/themeSwitcher";

export default function useTheme() {
  // INFO: support only light theme by now

  // const [theme, setTheme] = useState(Themes.light);
  // useEffect(() => {
  //   function changeThemeHandler(e: CustomEvent<Themes>) {
  //     const newTheme = e.detail;
  //     console.info("changed theme to: " + newTheme);
  //     setTheme(newTheme);
  //   }

  //   const [currentTheme, useAutoTheme] = getCurrentTheme();
  //   setTheme(currentTheme);

  //   document.addEventListener(CHANGE_THEME_EVENT, changeThemeHandler);
  //   return () => {
  //     document.removeEventListener(CHANGE_THEME_EVENT, changeThemeHandler);
  //   };
  // });
  // return theme;

  return Themes.light;
}
