import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { useCurrentUser, userIsBuddy } from "../../libs/apiHelpers/profile";
import { Routes } from "../../libs/constants";
import { showDebugStaff } from "../../libs/helpers/devHelpers";
import { version } from "../../package.json";
import BSIcon from "../BSIcon/BSIcon";
import styles from "./footer.module.css";
import DebugToggler from "../DebugToggler/DebugToggler";
import { reachGoal } from "../../libs/helpers/metrics";

const currentYear = new Date().getFullYear();
const _Link = ({ children, nofollow, ...otherProps }: { nofollow?: boolean } & React.PropsWithChildren<LinkProps>) => {
  return (
    <div>
      <Link {...otherProps} passHref>
        <a ref={nofollow && "nofollow"} className="pt-1 pb-1 py-xl-0 text-muted d-block d-xl-inline">
          {children}
        </a>
      </Link>
    </div>
  );
};

export const ServiceLinks = () => {
  return (
    <div className="py-3 container">
      <div className="w-100">
        <Row>
          <Col xs="6" xl="2">
            <_Link href={Routes.About}>About platform</_Link>
            <_Link prefetch={false} nofollow href={Routes.Help}>
              Help
            </_Link>
            <_Link prefetch={false} nofollow href={Routes.Contacts}>
              Contacts
            </_Link>
          </Col>
          <Col xs="6" xl="2">
            <_Link prefetch={false} nofollow href={Routes.Terms}>
              Terms
            </_Link>
            <_Link prefetch={false} nofollow href={Routes.Privacy}>
              Privacy
            </_Link>
            <_Link prefetch={false} nofollow href={Routes.Cookie}>
              Cookie
            </_Link>
          </Col>
        </Row>
        <div className="mt-4">
          TravelBuddy © {currentYear} | <small className="text-muted">{version}</small>
        </div>
        {showDebugStaff && (
          <div>
            <hr />
            <div>
              <b>Test env only:</b>
            </div>
            <_Link prefetch={false} nofollow href={Routes.Login}>
              Login
            </_Link>
            <_Link prefetch={false} nofollow href={Routes.Registration}>
              Registration
            </_Link>
            <DebugToggler />
            <hr />
          </div>
        )}
      </div>
    </div>
  );
};

enum NavLinkTab {
  Requests = "requests",
  BuddyRequests = "buddy_requests",
  Profile = "profile"
}
const NavLinkOptions = {
  [NavLinkTab.Requests]: {
    route: Routes.Home,
    icon: "file-earmark-plus",
    caption: "your requests"
  },
  [NavLinkTab.BuddyRequests]: {
    route: Routes.Claims,
    icon: "search",
    caption: "search requests"
  },
  [NavLinkTab.Profile]: {
    route: Routes.Profile,
    icon: "person-circle",
    caption: "profile"
  }
};

export default function Footer(): ReactElement {
  const { pathname } = useRouter();
  const { isLoggedIn } = useCurrentUser();
  const isBuddy = userIsBuddy();

  const NavLink = ({ tab }: { tab: NavLinkTab }) => {
    const { route, icon, caption } = NavLinkOptions[tab];
    return (
      <Link href={route}>
        <nav
          onClick={() => reachGoal("Click_Mobile_Nav", { type: tab })}
          className={`mx-1 text-center ${pathname === route && "text-primary"} ${styles.navigationItem}`}>
          <BSIcon size={22} name={icon} />
          <div className="mt-1">
            <small>{caption}</small>
          </div>
        </nav>
      </Link>
    );
  };

  return (
    <footer className="mt-auto" style={{ paddingTop: pathname === Routes.Home || pathname === Routes.About ? 0 : 95 }}>
      {isLoggedIn && (
        <div className="d-xl-none">
          <div className={`${styles.navigationTabs}`}>
            <div className="row no-gutters justify-content-center pt-1 pb-2">
              <NavLink tab={NavLinkTab.Requests} />
              {isBuddy ? <NavLink tab={NavLinkTab.BuddyRequests} /> : <div className="mx-5"></div>}
              <NavLink tab={NavLinkTab.Profile} />
            </div>
          </div>
        </div>
      )}
      <div className="d-none d-xl-block small text-muted bg-light pt-3">
        <ServiceLinks />
      </div>
    </footer>
  );
}
