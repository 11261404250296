import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import {
  isUserEmailVerified,
  useCurrentUser,
  userIsBuddy,
  useWalletStatus,
  WalletStatuses
} from "../../libs/apiHelpers/profile";
import { Routes } from "../../libs/constants";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import VerifyEmailAlert from "../Alerts/VerifyEmailAlert";
import NeedWalletAlert from "../Alerts/NeedWalletAlert";

interface Props {
  withHeader: boolean;
  children: ReactElement;
}

export default function PageLayout({ children, withHeader }: Props): ReactElement {
  const { user, isLoggedIn } = useCurrentUser();
  const { pathname } = useRouter();

  // static pages don't need functional alerts
  const needShowAlerts = ![
    Routes.About,
    Routes.Registration,
    Routes.Login,
    Routes.Help,
    Routes.Contacts,
    Routes.Reset,
    Routes.ResetConfirm,
    Routes.Terms,
    Routes.Privacy
  ].includes(pathname);

  const userEmailVerified = isUserEmailVerified();
  const { status: walletStatus, isLoading: isWalletStatusLoading } = useWalletStatus();
  const isUserNeedWallet = !isWalletStatusLoading && walletStatus !== WalletStatuses.registered;
  return (
    <div className="layout-wrapper">
      {withHeader && <Header />}
      {needShowAlerts && isLoggedIn && !userEmailVerified && <VerifyEmailAlert />}
      {needShowAlerts && userIsBuddy && isUserNeedWallet && <NeedWalletAlert />}
      {children}
      <Footer />
    </div>
  );
}

PageLayout.defaultProps = {
  withHeader: true
};
