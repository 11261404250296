import { CHANGE_THEME_EVENT } from "../constants";
import { getFromStorage, writeToStorage } from "./storage";

export enum Themes {
  light = "light",
  dark = "dark",
  auto = "auto"
}

const themeStorageKey = "theme";

function detectUserPreference() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches ? Themes.dark : Themes.light;
}

/**
 * @returns array, where 1 element is theme name; 2 - whether user prefer auto detect
 */
export function getCurrentTheme(): [Themes, boolean] {
  const themeFromStorage = getFromStorage(themeStorageKey) as Themes;
  if (themeFromStorage === null) {
    return [Themes.light, false];
  }
  if (themeFromStorage === Themes.auto) {
    return [detectUserPreference(), true];
  }
  return [themeFromStorage, false];
}

export function setTheme(theme: Themes) {
  document.dispatchEvent(new CustomEvent(CHANGE_THEME_EVENT, { detail: theme } as any));
  writeToStorage(themeStorageKey, theme);
  document.body.dataset.theme = theme === Themes.auto ? detectUserPreference() : theme;
}
