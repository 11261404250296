import React from "react";
import { useRouter } from "next/router";
import { Alert, Button } from "react-bootstrap";
import { Routes } from "../../libs/constants";
import { reachGoal } from "../../libs/helpers/metrics";

export default function NeedWalletAlert() {
  const router = useRouter();
  const onGotoProfileClick = () => {
    reachGoal("Click_To_Profile_In_Waller_Warning");
    router.replace(Routes.Profile);
  };
  return (
    <div>
      <Alert className="text-center mb-0" variant="warning">
        You need to create wallet in order to receive payments for requests that you apply.
        <br /> You can create it in profile.{" "}
        <Button onClick={onGotoProfileClick} size="sm" variant="link">
          Go to profile
        </Button>
      </Alert>
    </div>
  );
}
